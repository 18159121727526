.ManageVideos {
  margin: 1rem auto;
  text-align: center;
  background-color: darkorchid;
  background-image: linear-gradient(to bottom right, darkorchid, rebeccapurple);

  p,
  h1 {
    margin: 1rem;
  }

  a {
    color: springgreen;
    text-decoration: underline;
  }
}
