.YoutubeVideo {
  text-align: center;

  .border {
    display: inline-block;
    margin: 1rem auto 0;
    padding: 0.875rem;
    border: 0.125rem solid darkorchid;
    border-radius: 0.25rem;
    background-color: #222222;
    max-width: 37.5rem;
    width: 100%;

    iframe {
      width: 100%;
      display: block;
    }

    h3 {
      border-radius: 0.25rem;
      margin: 0.5rem auto 0;
      padding: 0.5rem 1rem;
      background-color: darkorchid;
      background-image: linear-gradient(
        to bottom right,
        darkorchid,
        rebeccapurple
      );
      display: inline-block;
      font-weight: 400;
      letter-spacing: 0.1rem;
    }
  }
}
