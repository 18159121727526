.Footer {
  padding: 1rem 0;
  text-align: center;
  background-color: darkorchid;
  background-image: linear-gradient(to bottom right, darkorchid, rebeccapurple);
  margin-top: 1rem;

  h3 {
    margin: 2rem auto;
    font-weight: 400;
    transition: letter-spacing 2s;
    letter-spacing: 0.5rem;
  }

  p {
    font-size: 0.75rem;
    margin: 3rem auto 2rem;
    a {
      color: springgreen;
      text-decoration: underline;
    }
  }
}
