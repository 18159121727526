* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#footer-grow {
  flex-grow: 1;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  color: mintcream;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
