.Navbar {
  text-align: center;
  background-color: darkorchid;
  background-image: linear-gradient(to bottom right, darkorchid, rebeccapurple);
  padding: 1rem 0;
  overflow: hidden;

  h2 {
    font-size: 2rem;
    font-weight: 300;
    transition: letter-spacing 2s, color 2s;
    letter-spacing: 0.5rem;
  }

  @media all and (max-width: 345px) {
    h2 {
      font-size: 1.6rem;
    }
  }

  @media all and (min-width: 768px) {
    h2:hover {
      letter-spacing: 1.5rem;
      color: springgreen;
    }
  }
}
